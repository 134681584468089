import $ from "jquery";
import Cookies from "js-cookie";

export default function winCollector() {
    setTimeout(() => {
        if (
            Cookies.get("lp_nl") == undefined &&
            (Cookies.get("lp_newsletter_popshown") == undefined ||
                Cookies.get("lp_newsletter_popshown") < 4)
        ) {
            $("#popup-newsletter-collector").animate(
                {
                    bottom: "20px",
                },
                1000,
            );
            let count = Cookies.get("lp_newsletter_popshown") || 0;
            count++;
            Cookies.set("lp_newsletter_popshown", count, {
                expires: 14,
                path: "/",
            });
        }
    }, 5000);
}
